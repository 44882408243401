import React from "react"
import { navigate } from "gatsby-link"

const routes = require("../../../types/routes")

class Newsletter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      valid: false,
      message: "Please enter a valid email",
    }
    this.component = null
    this.routes = routes
    this.form = React.createRef()
    this.input = React.createRef()
    this.button = React.createRef()
  }

  componentDidMount() {
    if (this.props.mode && this.props.mode === "light") {
      this.form.current.classList.add("light")
    }
  }

  onButtonStateLoading() {
    this.button.current.classList.add("spinner", "loading")
  }

  onButtonStateDefault() {
    this.button.current.classList.remove("spinner", "disabled", "loading")
  }

  onValidate() {
    const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const regex = RegExp(emailPattern)
    const valid = regex.test(this.input.current.value)
    this.setState({ valid })
    valid
      ? this.onMarkAsValid(this.input.current)
      : this.onMarkAsInvalid(this.input.current)
    return valid
  }

  onMarkAsInvalid(message) {
    this.setState({ message: message })
    this.input.current.classList.add("error")
    this.form.current.classList.add("error")
    this.button.current.classList.add("disabled")
  }

  onMarkAsValid() {
    this.message = "Please enter a valid email"
    this.input.current.classList.remove("error")
    this.form.current.classList.remove("error")
    this.button.current.classList.remove("disabled")
  }

  onEnterKeypress(e) {
    this.onMarkAsValid()
    if (e.key === "Enter") {
      e.preventDefault()
      this.onSubmitForm(e)
    }
  }

  onGetCookies(string) {
    return Object.fromEntries(
      document.cookie.split("; ").map(v => v.split("=").map(decodeURIComponent))
    )[string]
  }

  onSubmitForm(e) {
    this.onButtonStateLoading()
    if (this.onValidate()) {
      const hutk = this.onGetCookies("hubspotutk")
      const data = {
        fields: [{ name: "email", value: this.input.current.value }],
        context: {
          hutk: hutk,
          pageUri: window.location.href,
        },
      }
      this.ajax(
        this.form.current.method,
        this.form.current.action,
        data,
        data => {
          this.onButtonStateDefault()
          this.input.current.value = ""
          document.getElementById("newsletterForm").remove()
          document.getElementById("successMessage").style.display = "flex"
        },
        (error, data) => {
          const resp = JSON.parse(data)
          const message = resp.errors[0].message
          this.onButtonStateDefault()
          this.onMarkAsInvalid(message)
        }
      )
    } else {
      setTimeout(() => {
        this.onButtonStateDefault()
      }, 500)
    }
  }

  ajax(method, url, data, success, error) {
    var xhr = new XMLHttpRequest()
    xhr.open(method, url)
    xhr.setRequestHeader("Content-Type", "application/json")
    xhr.onreadystatechange = function() {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        success(xhr.response, xhr.responseType)
      } else {
        error(xhr.status, xhr.response, xhr.responseType)
      }
    }
    xhr.send(JSON.stringify(data))
  }

  render() {
    return (
      <div>
        <div
          id="successMessage"
          style={{ display: "none" }}
          className={"success-message"}
        >
          Submission complete
        </div>
        <form
          id={"newsletterForm"}
          className={"sign-up-form"}
          ref={this.form}
          method={"post"}
          action={
            "https://api.hsforms.com/submissions/v3/integration/submit/8614381/14937e70-8e0b-4ec0-8f73-2a5e7b0db5e0"
          }
        >
          <div className={"group"}>
            <input
              type={"text"}
              name="email"
              placeholder={"Enter your email"}
              onKeyDown={e => this.onEnterKeypress(e)}
              ref={this.input}
            />
            <button
              type={"button"}
              ref={this.button}
              onClick={e => this.onSubmitForm(e)}
            >
              SUBSCRIBE
              <div className={"spinner"}></div>
            </button>
          </div>
          <div className={"error-text"}>{this.message}</div>
        </form>
      </div>
    )
  }
}

export default Newsletter
